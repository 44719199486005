/* ========
animation
======== */

.parallaxItem{
	&.-fade{
		opacity: 0;
	}
	&.-fadeUp{
		opacity: 0;
		transform: translate(0,8px);
	}

	&.-animated{
		&.-fade{
			transition: 1s ease;
			opacity: 1;
		}
		&.-fadeUp{
			transition: 1s ease;
			opacity: 1;
			transform: translate(0,0);
		}
	}
}


@keyframes scrollanime{
	0%{
		top: -100%;
	}
	100%{
		top: 100%;
	}
}