/* ========
gnav
======== */

.gnav{
	padding: 8px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include mq(sp) {
		padding: 8px 3% 8px 5%;
	}
	&__logo{
		font-size: 4.8rem;
		color: #fff;
		position: relative;
		display: block;
		z-index: 1;
		@include mq(sp) {
			font-size: 2.6rem;
		}
	}
	&__content{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
	&__nav{
		margin: 16px 0 0;
		@include mq(tb) {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			padding: 0;
			margin: 0;
			background-color: palettes(main);
			z-index: 0;
			// transform: translate(0,-100%);
			// transition: .6s ease;
			display: none;
			&.-active{
				// transform: translate(0,0);
			}
		}
		ul{
			display: flex;
			line-height: 1.4;
			@include mq(tb) {
				display: block;
			}
		}
		li{
			padding-left: 24px;
			margin-left: 24px;
			border-left: 1px solid #fff;
			&:first-child{
				padding-left: 0;
				margin-left: 0;
				border-left: none;
			}
			@include mq(tb) {
				padding-left: 0;
				margin-left: 0;
				border-left: none;
				text-align: center;
				border: 1px solid rgba(#fff,.2);
			}
			a{
				display: block;
				color: #fff;
				font-size: 1.4rem;
				letter-spacing: .1em;
				padding: 0 0 2px;
				@include hover();
				@include mq(tb) {
					padding: 10px 0;
				}
			}
		}
	}
	&__btns{
		display: flex;
		position: relative;
		z-index: 1;
		&__booking{
			min-width: 90px;
			height: 22px;
			a{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				font-size: 1.4rem;
				letter-spacing: .1em;
				color: palettes(main,light);
				background-color: #fff;
				font-weight: 700;
				padding: 0 8px;
				@include hover();
			}
		}
		&__lang{
			border: 1px solid #fff;
			display: flex;
			margin: 0 0 0 16px;
			a{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 100%;
				font-size: 1rem;
				line-height: 1.7;
				font-weight: 700;
				color: #fff;
				@include hover();
				&.-current{
					background-color: #fff;
					color: palettes(main,light);
					pointer-events: none;
					span{
						border-bottom: 1px solid palettes(main,light);
					}
				}
				span{
					display: block;
					margin: 0 0 2px;
					padding: 0 2px;
				}
			}
		}
	}
}