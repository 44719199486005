/* ========
.layout
======== */
.l-inner {
  max-width: $pc-min-width;
  margin: 0 auto;
  @include mq(sp){
    max-width: 100%;
    padding: 0 5.2%;
  }
}

.l-wrapper {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
}