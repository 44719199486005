/*--==========================
base
============================--*/
@import "base/reset";
@import "base/vars";
@import "base/mixins";
@import "plugins/_00_plugins.scss";
@import "base/default";
@import "base/editor";
@import "base/animation";

/*--==========================
layout
============================--*/
@import "layout/_01_layout.scss";
@import "layout/_02_l-content.scss";
@import "layout/_02_l-footer.scss";
@import "layout/_02_l-header.scss";
@import "layout/_02_l-main.scss";
@import "layout/_02_l-side.scss";

/*--==========================
  module
============================--*/
@import "module/_00_button.scss";
@import "module/_00_text.scss";
@import "module/_00_title.scss";
@import "module/_01_footNav.scss";
@import "module/_01_gNav.scss";
@import "module/_02_mainvisual.scss";
@import "module/_02_section.scss";
@import "module/_03_content-img.scss";
@import "module/_03_insert-image.scss";
@import "module/_03_label-images.scss";
@import "module/_03_unit-col2.scss";
@import "module/_03_unit-textarea.scss";
@import "module/_99_modules.scss";

/*--==========================
  page
============================--*/
@import "page/_00_top.scss";

//先方追記
@import "base/addition";
