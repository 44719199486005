///*------------------------------
// mixin
// -------------------------------*/

// media query
@mixin mq($DEVICE) {
  @if $DEVICE == "pc" {
      @media (min-width: $bp-sp + 1) {
          @content;
      }
  }
  @else if $DEVICE == "sp" {
      @media (max-width: ($bp-sp)) {
          @content;
      }
  }
  @else if $DEVICE == "tb" {
      @media (max-width: ($bp-tb)) {
          @content;
      }
  }
}


// color palettes
@function palettes($key, $tone: base) {
  @return map-get( map-get($palettes, $key), $tone);
}

/*------------------------------
表示・非表示切替
-------------------------------*/
.pc{
  @include mq(sp){
    display: none !important;
  }
}
.sp{
  @include mq(pc){
    display: none !important;
  }
}


@mixin hover(){
  @include mq(pc) {
    cursor: pointer;
    transition: .4s ease;
    &:hover{
      opacity: .6;
    }
  }
}