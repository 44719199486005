/* ========
unit-textarea
======== */

.unit-textarea{
	padding: 0 0 32px;
	margin: 0 0 32px;
	border-bottom: 1px solid #cacaca;
	@include mq(sp) {
		padding: 0 0 24px;
		margin: 0 0 24px;
	}
}