/* ========
l-header
======== */
.l-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 99;
	&:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 100%;
		background-color: palettes(main);
		z-index: -1;
		transition: .6s ease;
	}

	&.-bgfill, &.-bgfill2{
		&:before{
			bottom: 0;
		}
	}
}