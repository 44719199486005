///*------------------------------
// 変数
// -------------------------------*/
//色
$color_text: #000;
// $color_main: #0d1646;
$color_main: #1e2664;
$color_link: #000;

//カラーバリエーション
$palettes: (
  main: (
    base: $color_main,
    light: #0032a1,
    dark: darken($color_main, 18%),
    lightest: lighten($color_main, 50%),
  ),
);

//font
$gothic: 'Noto Sans JP', '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;
$serif: 'Noto Serif JP', serif;

// レイアウト
$pc-min-width: 1080px;
$sp-min-width: 320px;

//breakpoint記述
//@include mq(sp){width:100%;}
$bp-sp: 750px !default;
$bp-tb: 1160px !default;




@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?wfqsj9');
  src:  url('../fonts/icomoon.eot?wfqsj9#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?wfqsj9') format('truetype'),
    url('../fonts/icomoon.woff?wfqsj9') format('woff'),
    url('../fonts/icomoon.svg?wfqsj9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	&:before{
	  /* use !important to prevent issues with browser extensions that change fonts */
	  font-family: 'icomoon' !important;
	  speak: none;
	  font-style: normal;
	  font-weight: normal;
	  font-variant: normal;
	  text-transform: none;
	  line-height: 1;

	  /* Better Font Rendering =========== */
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
	}
}

.icon-logo:before {
  content: "\e900";
}
.icon-bed:before {
  content: "\e901";
}
.icon-duck:before {
  content: "\e902";
}
.icon-sofa:before {
  content: "\e903";
}
.icon-tableware:before {
  content: "\e904";
}
