/* ========
mainvisual
======== */

.mainvisual{
	width: 100%;
	height: 100vh;
	max-height: 1080px;
	background: transparent url(../images/top/mainvisual.jpg) center center no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 0;
	@include mq(sp) {
		max-height: 680px;
	}
	&.-loaded{
		.mainvisual__bg{
			opacity: 0;
			transition: 4s linear;
			&:after{
				opacity: 0;
				transition: 2s linear;
			}
		}
	}
	&__bg{
		background: center center no-repeat;
		background-image: url(../images/top/mainvisual2.jpg);
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		&:after{
			content: '';
			background-color: palettes(main,dark);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: .95;
		}
	}
	&__ttl{
		text-align: center;
		font-family: $serif;
		font-size: 3.2rem;
		font-weight: 500;
		line-height: 1.8;
		letter-spacing: .1em;
		color: #fff;
		// opacity: 0;
		@include mq(sp) {
			font-size: 2.4rem;
		}
		>span{
			span{
				display: inline-block;
				transform-origin: left -8px bottom -8px;
				transform: rotate(2deg)translate(0,16px);
				opacity: 0;
				transition: transform 2s cubic-bezier(.18,.72,.31,1) .2s, opacity 2s ease .2s;
				min-width: .5em;
				&.-show{
					transform: rotate(0)translate(0,0);
					opacity: 1;
				}
			}
		}
	}
	&__scroll{
		display: block;
		position: absolute;
		width: 24px;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
		color: #fff;
		font-size: 1rem;
		font-weight: 700;
		letter-spacing: .2em;
		z-index: 1;
		.-text{
			display: block;
			transform: translate(10%,0);
	    writing-mode: vertical-rl;
	    padding: 0 0 8px;
	    height: 6em;
		}
		.-bar{
			display: block;
			position: relative;
			width: 1px;
			height: 72px;
			background-color: rgba(#fff,.5);
			margin: 0 auto;
			overflow: hidden;
			&:before{
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background-color: #fff;
				position: absolute;
				top: -100%;
				animation: scrollanime 2s ease-in-out infinite;
			}
		}
	}
}