/* ========
foot-nav
======== */

.foot-nav{
	padding: 40px 0;
	&__logo{
		color: palettes(main);
		font-size: 4.6rem;
		text-align: center;
		padding: 100px 0;
		@include mq(sp) {
			padding: 0;
			font-size: 5.2rem;
		}
	}
	&__nav{
		margin: 16px 0 0;
		@include mq(sp) {
			display: none;
		}
		ul{
			display: flex;
			justify-content: center;
			line-height: 1.4;
		}
		li{
			padding-left: 24px;
			margin-left: 24px;
			border-left: 1px solid palettes(main);
			&:first-child{
				padding-left: 0;
				margin-left: 0;
				border-left: none;
			}
			a{
				display: block;
				color: palettes(main);
				font-size: 1.4rem;
				letter-spacing: .1em;
				padding: 0 0 2px;
			}
		}
	}
	.copyright{
		font-size: 1rem;
		font-weight: 400;
		letter-spacing: .1em;
		color: palettes(main);
		text-align: center;
		display: block;
		margin: 1.5em 0 0;
		@include mq(sp) {
			margin: 0;
		}
	}
}