/* ========
button
======== */

.btn-basic{
	width: 368px;
	height: 52px;
	margin: 72px 0;
	@include mq(sp) {
		width: 100%;
		max-width: 420px;
	}
	a{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: palettes(main);
		color: #fff;
		font-size: 2rem;
		letter-spacing: .1em;
		font-weight: 400;
		position: relative;
		z-index: 0;
		overflow: hidden;
		border: 1px solid palettes(main);
		@include mq(pc) {
			transition: .4s ease;
			&:hover{
				color: palettes(main);
				&:before{
					left: 0;
					right: auto;
					width: 100%;
				}
			}
			&:before{
				content: '';
				// background-color: palettes(main,light);
				background-color: #fff;
				position: absolute;
				width: 0;
				top: 0;
				left: auto;
				right: 0;
				bottom: 0;
				z-index: -1;
				transition: .4s ease;
			}
		}
	}

	&.-center{
		margin-left: auto;
		margin-right: auto;
	}
}

.btn-menu{
	width: 34px;
	height: 25px;
	position: relative;
	display: none;
	margin: 0 0 0 24px;
	cursor: pointer;
	@include mq(tb) {
		display: block;
	}
	&.-active{
		span{
			background-color: transparent;
			&:before{
				top: 0;
				transform: rotate(45deg);
			}
			&:after{
				bottom: 0;
				transform: rotate(-45deg);
			}
		}
	}
	span{
		display: block;
		background-color: #fff;
		width: 50%;
		height: 3px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		&:before, &:after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 100%;
			height: 100%;
			background-color: #fff;
			transition: .4s ease;
		}
		&:before{
			top: -11px;
		}
		&:after{
			bottom: -11px;
		}
	}
}

.btn-fixed{
	width: 100%;
	height: 60px;
	position: fixed;
	left: 0;
	bottom: 0;
	transform: translate(0,100%);
	transition: .4s ease;
	z-index: 97;
	@include mq(pc) {
		display: none;
	}
	&.-show{
		transform: translate(0,0);
	}
	a{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: palettes(main);
		color: #fff;
		font-size: 2rem;
		letter-spacing: .1em;
		font-weight: 400;
		&:after{
			content: '';
			display: block;
			width: 64px;
			height: 2px;
			background-color: #fff;
			margin: 0 auto 8px;
		}
	}
}