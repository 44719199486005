/* ========
label-images
======== */

.label-images{
	display: flex;
	justify-content: space-between;
	&__row{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include mq(sp) {
			display: block;
		}
	}
	img{
		max-width: 100%;
	}
}