@charset "UTF-8";
/*--==========================
base
============================--*/
/*--------------------------------------------------------------------------
   reset
---------------------------------------------------------------------------*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong,
sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

img {
  border: 0;
  vertical-align: top; }

em {
  font-style: italic; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0; }

input, select, textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle; }

input:focus, textarea:focus, select:focus {
  outline: none; }

h1, h2, h3, h4, h5 {
  font-weight: inherit; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?wfqsj9");
  src: url("../fonts/icomoon.eot?wfqsj9#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?wfqsj9") format("truetype"), url("../fonts/icomoon.woff?wfqsj9") format("woff"), url("../fonts/icomoon.svg?wfqsj9#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-logo:before {
  content: "\e900"; }

.icon-bed:before {
  content: "\e901"; }

.icon-duck:before {
  content: "\e902"; }

.icon-sofa:before {
  content: "\e903"; }

.icon-tableware:before {
  content: "\e904"; }

/*------------------------------
表示・非表示切替
-------------------------------*/
@media (max-width: 750px) {
  .pc {
    display: none !important; } }

@media (min-width: 751px) {
  .sp {
    display: none !important; } }

.css {
  display: block; }

/* ========
html
======== */
html {
  font-size: 62.5%; }

/* ========
body
======== */
body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  font-family: "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  color: #000;
  line-height: 2;
  min-width: 1080px; }
  @media (max-width: 750px) {
    body {
      min-width: 320px; } }

/* ========
tags
======== */
* {
  box-sizing: border-box; }
  *::selection {
    background: rgba(200, 192, 235, 0.45);
    color: rgba(12, 16, 41, 0.45) #fff; }

a {
  color: inherit;
  outline: none;
  opacity: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a:link,
a:visited {
  text-decoration: none; }

a:hover {
  text-decoration: none;
  outline: none; }

a:active {
  outline: none; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

/* ========
input
======== */
input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none; }

select::-ms-expand {
  display: none; }

input, button, textarea, select {
  background-color: gray;
  display: block;
  padding: 5px; }

/* ========
animation
======== */
.parallaxItem.-fade {
  opacity: 0; }

.parallaxItem.-fadeUp {
  opacity: 0;
  transform: translate(0, 8px); }

.parallaxItem.-animated.-fade {
  transition: 1s ease;
  opacity: 1; }

.parallaxItem.-animated.-fadeUp {
  transition: 1s ease;
  opacity: 1;
  transform: translate(0, 0); }

@keyframes scrollanime {
  0% {
    top: -100%; }
  100% {
    top: 100%; } }

/*--==========================
layout
============================--*/
/* ========
.layout
======== */
.l-inner {
  max-width: 1080px;
  margin: 0 auto; }
  @media (max-width: 750px) {
    .l-inner {
      max-width: 100%;
      padding: 0 5.2%; } }

.l-wrapper {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden; }

/* ========
l-content
======== */
/* ========
l-footer
======== */
/* ========
l-header
======== */
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99; }
  .l-header:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100%;
    background-color: #1e2664;
    z-index: -1;
    transition: .6s ease; }
  .l-header.-bgfill:before, .l-header.-bgfill2:before {
    bottom: 0; }

/* ========
l-main
======== */
/* ========
l-side
======== */
/*--==========================
  module
============================--*/
/* ========
button
======== */
.btn-basic {
  width: 368px;
  height: 52px;
  margin: 72px 0; }
  @media (max-width: 750px) {
    .btn-basic {
      width: 100%;
      max-width: 420px; } }
  .btn-basic a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1e2664;
    color: #fff;
    font-size: 2rem;
    letter-spacing: .1em;
    font-weight: 400;
    position: relative;
    z-index: 0;
    overflow: hidden;
    border: 1px solid #1e2664; }
    @media (min-width: 751px) {
      .btn-basic a {
        transition: .4s ease; }
        .btn-basic a:hover {
          color: #1e2664; }
          .btn-basic a:hover:before {
            left: 0;
            right: auto;
            width: 100%; }
        .btn-basic a:before {
          content: '';
          background-color: #fff;
          position: absolute;
          width: 0;
          top: 0;
          left: auto;
          right: 0;
          bottom: 0;
          z-index: -1;
          transition: .4s ease; } }
  .btn-basic.-center {
    margin-left: auto;
    margin-right: auto; }

.btn-menu {
  width: 34px;
  height: 25px;
  position: relative;
  display: none;
  margin: 0 0 0 24px;
  cursor: pointer; }
  @media (max-width: 1160px) {
    .btn-menu {
      display: block; } }
  .btn-menu.-active span {
    background-color: transparent; }
    .btn-menu.-active span:before {
      top: 0;
      transform: rotate(45deg); }
    .btn-menu.-active span:after {
      bottom: 0;
      transform: rotate(-45deg); }
  .btn-menu span {
    display: block;
    background-color: #fff;
    width: 50%;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
    .btn-menu span:before, .btn-menu span:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transition: .4s ease; }
    .btn-menu span:before {
      top: -11px; }
    .btn-menu span:after {
      bottom: -11px; }

.btn-fixed {
  width: 100%;
  height: 60px;
  position: fixed;
  left: 0;
  bottom: 0;
  transform: translate(0, 100%);
  transition: .4s ease;
  z-index: 97; }
  @media (min-width: 751px) {
    .btn-fixed {
      display: none; } }
  .btn-fixed.-show {
    transform: translate(0, 0); }
  .btn-fixed a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1e2664;
    color: #fff;
    font-size: 2rem;
    letter-spacing: .1em;
    font-weight: 400; }
    .btn-fixed a:after {
      content: '';
      display: block;
      width: 64px;
      height: 2px;
      background-color: #fff;
      margin: 0 auto 8px; }

/* ========
text
======== */
.desc-serif {
  font-family: "Noto Serif JP", serif;
  font-size: 1.4rem;
  color: #1e2664;
  letter-spacing: 0;
  line-height: 2.1;
  font-weight: 500; }
  @media (max-width: 750px) {
    .desc-serif {
      font-size: 1.3rem; } }
  .desc-serif a {
    text-decoration: underline; }

.copy-serif {
  font-family: "Noto Serif JP", serif;
  font-size: 2.4rem;
  color: #1e2664;
  letter-spacing: .1em;
  line-height: 1.6;
  margin: 0 0 12px;
  font-weight: 500; }
  @media (max-width: 750px) {
    .copy-serif {
      font-size: 2rem; } }

.copy-gothic {
  font-family: "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-size: 2.4rem;
  color: #1e2664;
  letter-spacing: .1em;
  font-weight: 500;
  line-height: 1.6;
  margin: 0 0 12px; }
  .copy-gothic:before {
    margin-right: .5em; }
    @media (max-width: 750px) {
      .copy-gothic:before {
        font-size: 1.4rem; } }
  @media (max-width: 750px) {
    .copy-gothic {
      font-size: 1.7rem; } }

/* ========
title
======== */
.ttl-right-border {
  font-size: 2.4rem;
  letter-spacing: .1em;
  color: #1e2664;
  display: flex;
  align-items: center;
  margin: 0 0 16px;
  font-weight: 500; }
  .ttl-right-border:after {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    border-top: 1px solid #1e2664;
    border-bottom: 1px solid #1e2664;
    margin: 0 0 0 48px; }

.ttl-both-border {
  font-size: 2.4rem;
  letter-spacing: .1em;
  color: #1e2664;
  display: flex;
  align-items: center;
  margin: 0 0 88px;
  font-weight: 500; }
  @media (max-width: 750px) {
    .ttl-both-border {
      margin: 0 0 24px; } }
  .ttl-both-border:before, .ttl-both-border:after {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    border-top: 1px solid #1e2664;
    border-bottom: 1px solid #1e2664; }
    @media (max-width: 750px) {
      .ttl-both-border:before, .ttl-both-border:after {
        height: 3px; } }
  .ttl-both-border span {
    display: block;
    flex: 0 0 auto;
    margin: 0 48px; }
    @media (max-width: 750px) {
      .ttl-both-border span {
        margin: 0 24px; } }

/* ========
foot-nav
======== */
.foot-nav {
  padding: 40px 0; }
  .foot-nav__logo {
    color: #1e2664;
    font-size: 4.6rem;
    text-align: center;
    padding: 100px 0; }
    @media (max-width: 750px) {
      .foot-nav__logo {
        padding: 0;
        font-size: 5.2rem; } }
  .foot-nav__nav {
    margin: 16px 0 0; }
    @media (max-width: 750px) {
      .foot-nav__nav {
        display: none; } }
    .foot-nav__nav ul {
      display: flex;
      justify-content: center;
      line-height: 1.4; }
    .foot-nav__nav li {
      padding-left: 24px;
      margin-left: 24px;
      border-left: 1px solid #1e2664; }
      .foot-nav__nav li:first-child {
        padding-left: 0;
        margin-left: 0;
        border-left: none; }
      .foot-nav__nav li a {
        display: block;
        color: #1e2664;
        font-size: 1.4rem;
        letter-spacing: .1em;
        padding: 0 0 2px; }
  .foot-nav .copyright {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .1em;
    color: #1e2664;
    text-align: center;
    display: block;
    margin: 1.5em 0 0; }
    @media (max-width: 750px) {
      .foot-nav .copyright {
        margin: 0; } }

/* ========
gnav
======== */
.gnav {
  padding: 8px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 750px) {
    .gnav {
      padding: 8px 3% 8px 5%; } }
  .gnav__logo {
    font-size: 4.8rem;
    color: #fff;
    position: relative;
    display: block;
    z-index: 1; }
    @media (max-width: 750px) {
      .gnav__logo {
        font-size: 2.6rem; } }
  .gnav__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .gnav__nav {
    margin: 16px 0 0; }
    @media (max-width: 1160px) {
      .gnav__nav {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0;
        background-color: #1e2664;
        z-index: 0;
        display: none; } }
    .gnav__nav ul {
      display: flex;
      line-height: 1.4; }
      @media (max-width: 1160px) {
        .gnav__nav ul {
          display: block; } }
    .gnav__nav li {
      padding-left: 24px;
      margin-left: 24px;
      border-left: 1px solid #fff; }
      .gnav__nav li:first-child {
        padding-left: 0;
        margin-left: 0;
        border-left: none; }
      @media (max-width: 1160px) {
        .gnav__nav li {
          padding-left: 0;
          margin-left: 0;
          border-left: none;
          text-align: center;
          border: 1px solid rgba(255, 255, 255, 0.2); } }
      .gnav__nav li a {
        display: block;
        color: #fff;
        font-size: 1.4rem;
        letter-spacing: .1em;
        padding: 0 0 2px; }
        @media (min-width: 751px) {
          .gnav__nav li a {
            cursor: pointer;
            transition: .4s ease; }
            .gnav__nav li a:hover {
              opacity: .6; } }
        @media (max-width: 1160px) {
          .gnav__nav li a {
            padding: 10px 0; } }
  .gnav__btns {
    display: flex;
    position: relative;
    z-index: 1; }
    .gnav__btns__booking {
      min-width: 90px;
      height: 22px; }
      .gnav__btns__booking a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 1.4rem;
        letter-spacing: .1em;
        color: #0032a1;
        background-color: #fff;
        font-weight: 700;
        padding: 0 8px; }
        @media (min-width: 751px) {
          .gnav__btns__booking a {
            cursor: pointer;
            transition: .4s ease; }
            .gnav__btns__booking a:hover {
              opacity: .6; } }
    .gnav__btns__lang {
      border: 1px solid #fff;
      display: flex;
      margin: 0 0 0 16px; }
      .gnav__btns__lang a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 100%;
        font-size: 1rem;
        line-height: 1.7;
        font-weight: 700;
        color: #fff; }
        @media (min-width: 751px) {
          .gnav__btns__lang a {
            cursor: pointer;
            transition: .4s ease; }
            .gnav__btns__lang a:hover {
              opacity: .6; } }
        .gnav__btns__lang a.-current {
          background-color: #fff;
          color: #0032a1;
          pointer-events: none; }
          .gnav__btns__lang a.-current span {
            border-bottom: 1px solid #0032a1; }
        .gnav__btns__lang a span {
          display: block;
          margin: 0 0 2px;
          padding: 0 2px; }

/* ========
mainvisual
======== */
.mainvisual {
  width: 100%;
  height: 100vh;
  max-height: 1080px;
  background: transparent url(../images/top/mainvisual.jpg) center center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0; }
  @media (max-width: 750px) {
    .mainvisual {
      max-height: 680px; } }
  .mainvisual.-loaded .mainvisual__bg {
    opacity: 0;
    transition: 4s linear; }
    .mainvisual.-loaded .mainvisual__bg:after {
      opacity: 0;
      transition: 2s linear; }
  .mainvisual__bg {
    background: center center no-repeat;
    background-image: url(../images/top/mainvisual2.jpg);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
    .mainvisual__bg:after {
      content: '';
      background-color: #090b1d;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .95; }
  .mainvisual__ttl {
    text-align: center;
    font-family: "Noto Serif JP", serif;
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.8;
    letter-spacing: .1em;
    color: #fff; }
    @media (max-width: 750px) {
      .mainvisual__ttl {
        font-size: 2.4rem; } }
    .mainvisual__ttl > span span {
      display: inline-block;
      transform-origin: left -8px bottom -8px;
      transform: rotate(2deg) translate(0, 16px);
      opacity: 0;
      transition: transform 2s cubic-bezier(0.18, 0.72, 0.31, 1) 0.2s, opacity 2s ease 0.2s;
      min-width: .5em; }
      .mainvisual__ttl > span span.-show {
        transform: rotate(0) translate(0, 0);
        opacity: 1; }
  .mainvisual__scroll {
    display: block;
    position: absolute;
    width: 24px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .2em;
    z-index: 1; }
    .mainvisual__scroll .-text {
      display: block;
      transform: translate(10%, 0);
      writing-mode: vertical-rl;
      padding: 0 0 8px;
      height: 6em; }
    .mainvisual__scroll .-bar {
      display: block;
      position: relative;
      width: 1px;
      height: 72px;
      background-color: rgba(255, 255, 255, 0.5);
      margin: 0 auto;
      overflow: hidden; }
      .mainvisual__scroll .-bar:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: -100%;
        animation: scrollanime 2s ease-in-out infinite; }

/* ========
section
======== */
.s-about {
  padding: 100px 0; }
  @media (max-width: 750px) {
    .s-about {
      padding: 80px 0 60px; } }
  .s-about .icon-logo {
    text-align: center;
    font-size: 4.8rem;
    color: #1e2664;
    margin: 0 0 100px; }
    @media (max-width: 750px) {
      .s-about .icon-logo {
        font-size: 5.2rem;
        margin: 0 0 16px; } }
  .s-about__box02 {
    margin: 50px 0 0;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 750px) {
      .s-about__box02 {
        display: block;
        position: relative;
        margin: 24px 0 0; } }
    .s-about__box02__left {
      width: 524px;
      flex: 0 0 auto; }
      @media (max-width: 750px) {
        .s-about__box02__left {
          position: absolute;
          width: calc(50% - 8px);
          left: 0;
          bottom: 0; } }
    .s-about__box02__right {
      width: 100%;
      margin: 0 0 0 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      @media (max-width: 750px) {
        .s-about__box02__right {
          margin: 0; }
          .s-about__box02__right img {
            width: calc(50% - 8px);
            margin: 32px 0 0 auto; } }
    .s-about__box02__textarea {
      padding: 48px 0 0 48px; }
      @media (max-width: 750px) {
        .s-about__box02__textarea {
          padding: 0; } }

.s-floor {
  padding: 200px 0 0; }
  @media (max-width: 750px) {
    .s-floor {
      padding: 70px 0 0; } }
  .s-floor__box {
    display: flex;
    margin: 150px 0; }
    @media (max-width: 750px) {
      .s-floor__box {
        display: block;
        margin: 40px 0; } }
    .s-floor__box.-reverse {
      flex-direction: row-reverse; }
    .s-floor__box__left {
      flex: 0 0 auto; }
      @media (max-width: 750px) {
        .s-floor__box__left {
          margin: 0 0 24px; } }
    .s-floor__box__right {
      width: 100%;
      padding: 32px 0 0 72px; }
      @media (max-width: 750px) {
        .s-floor__box__right {
          padding: 0; } }

.s-howto {
  padding: 0 0 100px; }
  @media (max-width: 750px) {
    .s-howto {
      padding: 0 0 60px; } }
  .s-howto__inner {
    display: flex;
    justify-content: space-between; }
  .s-howto__ttl {
    color: #1e2664;
    font-family: "Noto Serif JP", serif;
    font-size: 3.6rem;
    writing-mode: vertical-rl;
    letter-spacing: .1em;
    font-weight: 500;
    padding: 0 0 0 112px; }
    @media (max-width: 750px) {
      .s-howto__ttl {
        font-size: 3rem;
        line-height: 1;
        padding: 0 26px 0 0; } }
  .s-howto__content {
    width: 740px; }
    @media (max-width: 750px) {
      .s-howto__content {
        width: calc(100% - 48px); } }
  .s-howto__box {
    width: 100%;
    position: relative;
    margin: 0 0 100px; }
    @media (max-width: 750px) {
      .s-howto__box {
        margin: 0 0 40px; } }
    .s-howto__box.-reverse .s-howto__copy {
      left: auto;
      right: 0; }
    .s-howto__box.-reverse .s-howto__img img {
      margin: 0 auto 0 0; }
  .s-howto__copy {
    color: #1e2664;
    font-family: "Noto Serif JP", serif;
    font-size: 1.8rem;
    writing-mode: vertical-rl;
    letter-spacing: .1em;
    font-weight: 500;
    line-height: 1.8;
    position: absolute;
    top: 0;
    left: 0; }
    @media (max-width: 750px) {
      .s-howto__copy {
        font-size: 1.3rem; } }
  .s-howto__img img {
    margin: 0 0 0 auto; }
    @media (max-width: 750px) {
      .s-howto__img img {
        width: calc(100% - 72px); } }

.s-spot {
  padding: 100px 0 230px; }
  @media (max-width: 750px) {
    .s-spot {
      padding: 60px 0 0; } }
  .s-spot__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .s-spot__box {
    width: 504px;
    margin: 0 0 130px; }
    @media (min-width: 751px) {
      .s-spot__box:nth-child(2n) {
        transform: translate(0, 180px); } }
    @media (max-width: 750px) {
      .s-spot__box {
        margin: 0 0 50px; } }
  .s-spot__img {
    margin: 0 0 16px; }
  @media (max-width: 750px) {
    .s-spot .copy-serif {
      margin: 0 0 4px; } }
  .s-spot .desc-serif {
    letter-spacing: 0; }

.s-info {
  padding: 90px 0 160px; }
  @media (max-width: 750px) {
    .s-info {
      padding: 60px 0 20px; } }

.s-facility {
  padding: 0 0 130px; }
  @media (max-width: 750px) {
    .s-facility {
      padding: 0 0 40px; } }

.s-access {
  padding: 100px 0 0; }
  .s-access__inner {
    display: flex;
    flex-direction: row-reverse; }
    @media (max-width: 750px) {
      .s-access__inner {
        display: block; } }
  .s-access__map iframe {
    display: block;
    width: 526px;
    height: 556px; }
    @media (max-width: 750px) {
      .s-access__map iframe {
        width: 100%;
        height: 54vw; } }
  .s-access__content {
    margin: 0 0 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (max-width: 750px) {
      .s-access__content {
        margin: 0 0 28px; } }
  .s-access__textarea {
    padding: 24px 40px 0 40px; }
    @media (max-width: 750px) {
      .s-access__textarea {
        padding: 0 0 24px; } }

/* ========
content-img
======== */
.content-img {
  margin: 0 0 88px; }
  @media (max-width: 750px) {
    .content-img {
      margin: 0 0 40px; } }

/* ========
insert-image
======== */
.insert-image img {
  width: 100%; }

/* ========
label-images
======== */
.label-images {
  display: flex;
  justify-content: space-between; }
  .label-images__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (max-width: 750px) {
      .label-images__row {
        display: block; } }
  .label-images img {
    max-width: 100%; }

/* ========
unit-col2
======== */
.unit-col2 {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 750px) {
    .unit-col2 {
      display: block; } }
  .unit-col2__side {
    width: 340px;
    flex: 0 0 auto; }
    @media (max-width: 750px) {
      .unit-col2__side {
        width: 100%;
        margin: 0 0 24px; } }
  .unit-col2__content {
    width: 690px;
    flex: 0 0 auto; }
    @media (max-width: 750px) {
      .unit-col2__content {
        width: 100%; } }

/* ========
unit-textarea
======== */
.unit-textarea {
  padding: 0 0 32px;
  margin: 0 0 32px;
  border-bottom: 1px solid #cacaca; }
  @media (max-width: 750px) {
    .unit-textarea {
      padding: 0 0 24px;
      margin: 0 0 24px; } }

/* ========
modules
======== */
/*--==========================
  page
============================--*/
/* ========
top
======== */
