/* ========
unit-col2
======== */

.unit-col2{
	display: flex;
	justify-content: space-between;
	@include mq(sp) {
		display: block;
	}
	&__side{
		width: 340px;
		flex: 0 0 auto;
		@include mq(sp) {
			width: 100%;
			margin: 0 0 24px;
		}
	}
	&__content{
		width: 690px;
		flex: 0 0 auto;
		@include mq(sp) {
			width: 100%;
		}
	}
}