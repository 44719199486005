/* ========
text
======== */

.desc-serif{
	font-family: $serif;
	font-size: 1.4rem;
	color: palettes(main);
	letter-spacing: 0;
	line-height: 2.1;
	font-weight: 500;
	@include mq(sp) {
		font-size: 1.3rem;
	}
	a{
		text-decoration: underline;
	}
}

.copy-serif{
	font-family: $serif;
	font-size: 2.4rem;
	color: palettes(main);
	letter-spacing: .1em;
	line-height: 1.6;
	margin: 0 0 12px;
	font-weight: 500;
	@include mq(sp) {
		font-size: 2rem;
	}
}

.copy-gothic{
	font-family: $gothic;
	font-size: 2.4rem;
	color: palettes(main);
	letter-spacing: .1em;
	font-weight: 500;
	line-height: 1.6;
	margin: 0 0 12px;
	&:before{
		margin-right: .5em;
		@include mq(sp) {
			font-size: 1.4rem;
		}
	}
	@include mq(sp) {
		font-size: 1.7rem;
	}
}