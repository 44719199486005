/* ========
section
======== */

.s-general{
}

.s-about{
	padding: 100px 0;
	@include mq(sp) {
		padding: 80px 0 60px;
	}
	.icon-logo{
		text-align: center;
		font-size: 4.8rem;
		color: palettes(main);
		margin: 0 0 100px;
		@include mq(sp) {
			font-size: 5.2rem;
			margin: 0 0 16px;
		}
	}
	&__box02{
		margin: 50px 0 0;
		display: flex;
		justify-content: space-between;
		@include mq(sp) {
			display: block;
			position: relative;
			margin: 24px 0 0;
		}
		&__left{
			width: 524px;
			flex: 0 0 auto;
			@include mq(sp) {
				position: absolute;
				width: calc(50% - 8px);
				left: 0;
				bottom: 0;
			}
		}
		&__right{
			width: 100%;
			margin: 0 0 0 50px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@include mq(sp) {
				margin: 0;
				img{
					width: calc(50% - 8px);
					margin: 32px 0 0 auto;
				}
			}
		}
		&__textarea{
			padding: 48px 0 0 48px;
			@include mq(sp) {
				padding: 0;
			}
		}
	}
}

.s-floor{
	padding: 200px 0 0;
	@include mq(sp) {
		padding: 70px 0 0;
	}
	&__box{
		display: flex;
		margin: 150px 0;
		@include mq(sp) {
			display: block;
			margin: 40px 0;
		}
		&.-reverse{
			flex-direction: row-reverse;
		}
		&__left{
			flex: 0 0 auto;
			@include mq(sp) {
				margin: 0 0 24px;
			}
		}
		&__right{
			width: 100%;
			padding: 32px 0 0 72px;
			@include mq(sp) {
				padding: 0;
			}
		}
	}
}


.s-howto{
	padding: 0 0 100px;
	@include mq(sp) {
		padding: 0 0 60px;
	}
	&__inner{
		display: flex;
		justify-content: space-between;
	}
	&__ttl{
		color: palettes(main);
		font-family: $serif;
		font-size: 3.6rem;
		writing-mode: vertical-rl;
		letter-spacing: .1em;
		font-weight: 500;
		padding: 0 0 0 112px;
		@include mq(sp) {
			font-size: 3rem;
			line-height: 1;
			padding: 0 26px 0 0;
		}
	}
	&__content{
		width: 740px;
		@include mq(sp) {
			width: calc(100% - 48px);
		}
	}
	&__box{
		width: 100%;
		position: relative;
		margin: 0 0 100px;
		@include mq(sp) {
			margin: 0 0 40px;
		}
		&.-reverse{
			.s-howto__copy{
				left: auto;
				right: 0;
			}
			.s-howto__img{
				img{
					margin: 0 auto 0 0;
				}
			}
		}
	}
	&__copy{
		color: palettes(main);
		font-family: $serif;
		font-size: 1.8rem;
		writing-mode: vertical-rl;
		letter-spacing: .1em;
		font-weight: 500;
		line-height: 1.8;
		position: absolute;
		top: 0;
		left: 0;
		@include mq(sp) {
			font-size: 1.3rem;
		}
	}
	&__img{
		img{
			margin: 0 0 0 auto;
			@include mq(sp) {
				width: calc(100% - 72px);
			}
		}
	}
}


.s-spot{
	padding: 100px 0 230px;
	@include mq(sp) {
		padding: 60px 0 0;
	}
	&__content{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	&__box{
		width: 504px;
		margin: 0 0 130px;
		@include mq(pc) {
			&:nth-child(2n){
				transform: translate(0,180px);
			}
		}
		@include mq(sp) {
			margin: 0 0 50px;
		}
	}
	&__img{
		margin: 0 0 16px;
	}
	.copy-serif{
		@include mq(sp) {
			margin: 0 0 4px;
		}
	}
	.desc-serif{
		letter-spacing: 0;
	}
}


.s-info{
	padding: 90px 0 160px;
	@include mq(sp) {
		padding: 60px 0 20px;
	}
}


.s-facility{
	padding: 0 0 130px;
	@include mq(sp) {
		padding: 0 0 40px;
	}
}


.s-access{
	padding: 100px 0 0;
	&__inner{
		display: flex;
		flex-direction: row-reverse;
		@include mq(sp) {
			display: block;
		}
	}
	&__map{
		iframe{
			display: block;
			width: 526px;
			height: 556px;
			@include mq(sp) {
				width: 100%;
				height: 54vw;
			}
		}
	}
	&__content{
		margin: 0 0 0 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include mq(sp) {
			margin: 0 0 28px;
		}
	}
	&__textarea{
		padding: 24px 40px 0 40px;
		@include mq(sp) {
			padding: 0 0 24px;
		}
	}
}