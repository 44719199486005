/* ========
title
======== */


.ttl-right-border{
	font-size: 2.4rem;
	letter-spacing: .1em;
	color: palettes(main);
	display: flex;
	align-items: center;
	margin: 0 0 16px;
	font-weight: 500;
	&:after{
		content: '';
		display: block;
		width: 100%;
		height: 5px;
		border-top: 1px solid palettes(main);
		border-bottom: 1px solid palettes(main);
		margin: 0 0 0 48px;
	}
}

.ttl-both-border{
	font-size: 2.4rem;
	letter-spacing: .1em;
	color: palettes(main);
	display: flex;
	align-items: center;
	margin: 0 0 88px;
	font-weight: 500;
	@include mq(sp) {
		margin: 0 0 24px;
	}
	&:before, &:after{
		content: '';
		display: block;
		width: 100%;
		height: 5px;
		border-top: 1px solid palettes(main);
		border-bottom: 1px solid palettes(main);
		@include mq(sp) {
			height: 3px;
		}
	}
	span{
		display: block;
		flex: 0 0 auto;
		margin: 0 48px;
		@include mq(sp) {
			margin: 0 24px;
		}
	}
}